import React, { useState } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import styled from "styled-components";
import retirementImage from "../assets/images/retirement_1.jpg";
import { FiChevronRight } from "react-icons/fi";
import { AiOutlineDollar } from "react-icons/ai";
import { RiShieldCheckLine } from "react-icons/ri";
import { BsShieldLock, BsClock } from "react-icons/bs";

const baseHeroTextWidth = "350px";

const StyledPageContainer = styled.div`
  width: 100%;

  .hero {
    position: relative;
    width: 100%;
    height: 600px;
    text-align: left;
    color: #fff;
  }
  .hero__text--overlay {
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .hero__text--container {
    position: relative;
    margin: auto;
    height: 100%;
  }
  .hero__text {
    position: absolute;
    color: black;
    font-size: 3.25em;
    font-weight: bold;
    width: calc(${baseHeroTextWidth} * 2);
    left: 15%;
    top: 25%;
    transform: translateY(calc(-50% + 30px));
    z-index: 10;
  }
  .hero__image {
    // background-image: url(https://d31s10tn3clc14.cloudfront.net/imgs/2020-homepage-new-images/HeroIMG_Test/Lydia_HeroIMG.jpg);
    background-image: url(${retirementImage});

    height: 100%;
    background-position: left top;
    background-size: cover;
    box-shadow: inset 0px 40px 30px 0px var(--off-white);

    filter: blur(3px);
    // -webkit-filter: blur(1px);
  }
  .hero-cta-button {
    background-color: var(--green);
    font-size: 3rem;
    color: white;
    text-decoration: none;
  }

  .main-content {
    display: grid;
    gap: 2rem;
    --columns: 2;
    grid-template-columns: 1fr 2fr;
    margin-bottom: 100px;

    @media (max-width: 800px) {
      grid-template-columns: 1fr;
      --columns: 1;
    }
  }

  .info-section {
    text-align: center;
    line-height: 1.2;
    max-width: var(--max-width);
    width: calc(100% * 0.75);
    padding: var(--container-block-padding) var(--container-inline-padding);
    margin: 30px auto 90px auto;

    &__container--inverted {
      background-color: var(--dark-blue);
      color: white;
      display: flex;
    }

    &__header {
      font-weight: bold;
      font-size: 4rem;
      color: #777;
      margin-top: 24px;
    }

    &__subtitle--large,
    &__subtitle--small {
      font-weight: bold;
      color: var(--light-blue);
      margin-top: 16px;
    }
    &__subtitle--small {
      font-size: 2.5rem;
    }
    &__subtitle--large {
      font-size: 3.5rem;
    }

    &__breakbar {
      background-color: var(--dark-blue);
    }
  }

  .info-section__group {
    text-align: left;
    margin-top: 48px;
  }
  .group__title {
    font-weight: bold;
  }
  .group__content {
    margin-top: 12px;
    line-height: 1.5;
  }
  .group__icon-list-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &__icon {
      margin-right: 16px;
    }
    &__description {
    }
  }

  .icon-list-item__icon {
    display: flex;
    margin-right: 16px;
    font-size: 1.4em;
    width: 35px;
  }
  .icon-list-item__description {
    font-size: 1.1em;
  }

  .webinars-link {
    // text-decoration: none;
    // border-bottom: 2px solid var(--light-blue);
    font-weight: bold;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.9em;

    & svg {
      // font-size: 24px;
      margin-left: 5px;
      transition: margin 1s ease-in-out;
      animation: sway 1s linear infinite;
    }
  }

  /**
   * MEDIA QUERIES
   */
  @media (max-width: 1300px) {
    .hero__text {
      right: calc(50% + 40px);
    }
  }
  @media (max-width: 1120px) {
    .hero__image {
      background-position-x: 60%;
    }
    .hero__text {
      left: 12%;
    }
  }
  @media (max-width: 768px) {
    .hero {
      height: 350px;
    }
    .hero__text {
      width: ${baseHeroTextWidth};
      left: calc(50% - (${baseHeroTextWidth} / 2));
      top: 25%;
      text-align: center;
      font-size: 2em;
    }
    .hero-cta-button {
      margin-top: 24px;
    }
  }

  /**
   * ANIMATIONS
   */
  @keyframes sway {
    0% {
      margin-left: 0;
    }
    50% {
      margin-left: 5px;
    }
    100% {
      margin-left: 0;
    }
  }
`;

export default function HomePage({ data }) {
  // state

  // funcs

  // vars

  return (
    <StyledPageContainer>
      <div className="hero">
        <div className="hero__text--overlay">
          <div className="hero__text--container max-width">
            <div className="hero__text">
              <div>Are you prepared for the next step?</div>

              <Link to="/upload" className="hero-cta-button button">
                Upload policy for review!
              </Link>
            </div>
          </div>
        </div>
        <div className="hero__image"></div>
      </div>

      <section className="info-section">
        <div className="info-section__header">Where to Begin</div>
        <div className="info-section__subtitle--large">
          We understand that the financial process is tough. That's why we have
          created a diverse group of advisors whose sole purpose is to help you!
        </div>

        <div className="info-section__group">
          <div className="group__title">
            Let us help you on your path to freedom
          </div>
          <div className="group__content">
            Upload your current documents and we will give them a thorough
            analysis. If we see any areas that we think can be improved, we will
            let you know immediately and walk you through the process of getting
            those enhancements put in place.
          </div>
        </div>
        <div className="info-section__group">
          <div className="group__title">Who are we?</div>
          <div className="group__content">
            We are a band of advisors that specialize in all areas of finance
            and who have worked with all kinds of people. Are you a mechanic?
            We've got you covered. Doctor? Ditto. Clerk, lawyer, engineer,
            scientist, and anything else? We've helped them all, and we can't
            wait to help you.
          </div>
        </div>
        <div className="info-section__group">
          <div className="group__title">
            Our main goal? Help you reach your goal
          </div>
          <div className="group__content">
            With years of experience and a passion to see others strive, we will
            do everything we can to set you up for a successful, financially
            free future. Have questions? Just ask!
          </div>
        </div>
      </section>

      <section className="info-section__container--inverted">
        <section className="info-section">
          <div
            className="info-section__header"
            style={{
              color: "white",
              borderBottom: "2px solid white",
              borderRadius: "10%",
            }}
          >
            Our Guarantee
          </div>

          <div className="info-section__group">
            <div className="group__icon-list-item">
              <div className="icon-list-item__icon">
                <AiOutlineDollar />
              </div>
              <div className="icon-list-item__description">
                Our document analysis and advisor matchup will always be free!
              </div>
            </div>
          </div>
          <div className="info-section__group">
            <div className="group__icon-list-item">
              <div className="icon-list-item__icon">
                <BsShieldLock />
              </div>
              <div className="icon-list-item__description">
                Your information is always submitted over a secure connection
                and any uploaded documents are never stored on our servers.
              </div>
            </div>
          </div>
          <div className="info-section__group">
            <div className="group__icon-list-item">
              <div className="icon-list-item__icon">
                <RiShieldCheckLine />
              </div>
              <div className="icon-list-item__description">
                Each advisor is certified and is vetted before they are allowed
                to be on our list.
              </div>
            </div>
          </div>
          <div className="info-section__group">
            <div className="group__icon-list-item">
              <div className="icon-list-item__icon">
                <BsClock />
              </div>
              <div className="icon-list-item__description">
                Our advisors will reach out to legitimate requests within 2
                business days.
              </div>
            </div>
          </div>
        </section>
      </section>

      <section className="info-section">
        <div className="info-section__header">Services</div>
        <div className="info-section__subtitle--large">
          We have a wide range of services to help you succeed. Take a look
          below to see if one or more services match up with your current
          interests.
        </div>

        <div className="info-section__group">
          <div className="group__title">Insurance</div>
          <div className="group__content">
            <ul>
              <li>Life</li>
              <li>Disability</li>
              <li>Long-term care</li>
              <li>and more...</li>
            </ul>
          </div>
        </div>
        <div className="info-section__group">
          <div className="group__title">Retirement</div>
          <div className="group__content">
            <ul>
              <li>401k</li>
              <li>IRA</li>
              <li>Annuities</li>
              <li>and more...</li>
            </ul>
          </div>
        </div>
        <div className="info-section__group">
          <div className="group__title">Leadership</div>
          <div className="group__content">
            Interested in enhancing your leadership skills? We also host live
            and recorded webinars for motivated customers to watch and learn
            from. We love seeing customers who are motivated to continue
            learning and do the most for themselves which is why we want to help
            in that process.{" "}
            <Link to="/resources" className="webinars-link">
              <span>Check them out</span>
              <FiChevronRight />
            </Link>
          </div>
        </div>
      </section>
    </StyledPageContainer>
  );
}

export const query = graphql`
  query {
    webinars: allSanityWebinar {
      edges {
        node {
          _id
          _type
          shortDescription
          slug {
            _key
            _type
            current
          }
          price
          longDescription
          parent {
            id
          }
          name

          image {
            asset {
              url
              fluid(maxWidth: 1000, maxHeight: 750) {
                ...GatsbySanityImageFluid
              }
              metadata {
                lqip
              }
            }
          }
        }
      }
    }
  }
`;
